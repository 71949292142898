import React, { useEffect, useState } from "react";

const App = () => {
  const [refreshToken, setRefreshToken] = useState("");

  const handleLogin = () => {
    const clientId =
      "amzn1.application-oa2-client.7ca31cddd9a14d449dd1ed572f2308b3";
    const redirectUri = "https://lw-125-frontend.wms.losung360.com/";
    const scope = "postal_code"; // Replace with the desired scope

    const authUrl = `https://www.amazon.com/ap/oa?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scope}&response_type=code`;

    window.location.href = authUrl;
  };

  const redirectToHome = () => {
    window.location.href = "/";
  };

  const handleTokenExchange = (authorizationCode) => {
    const clientId =
      "amzn1.application-oa2-client.7ca31cddd9a14d449dd1ed572f2308b3";
    const clientSecret =
      "amzn1.oa2-cs.v1.09552c5ea3ba1f4e02b25c5c62a01db4020b0589af5ee1c9a3c0db3258fdf12f";
    const redirectUri = "https://lw-125-frontend.wms.losung360.com/";

    const requestBody = new URLSearchParams();
    requestBody.append("grant_type", "authorization_code");
    requestBody.append("code", authorizationCode);
    requestBody.append("client_id", clientId);
    requestBody.append("client_secret", clientSecret);
    requestBody.append("redirect_uri", redirectUri);

    fetch("https://api.amazon.com/auth/o2/token", {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: requestBody.toString(),
    })
      .then((response) => response.json())
      .then((data) => {
        const receivedRefreshToken = data.refresh_token;
        setRefreshToken(receivedRefreshToken);
        // redirectToHome();
      })
      .catch((error) => {
        console.error("Error exchanging token:", error);
      });
  };

  useEffect(() => {
    const handleCallback = () => {
      const urlParams = new URLSearchParams(window.location.search);
      const authorizationCode = urlParams.get("code");

      if (authorizationCode) {
        handleTokenExchange(authorizationCode);
      }
    };

    handleCallback();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    localStorage.setItem("refreshToken", JSON.stringify(refreshToken));
  }, []);

  console.log(refreshToken, "Refresh Token");

  const getToken = localStorage.getItem("refreshToken");

  return (
    <div>
      <button onClick={handleLogin}>Login with Amazon</button>
      <p>Refresh Token: {JSON.parse(getToken)}</p>
      <p>Refresh Token: {refreshToken}</p>
    </div>
  );
};

export default App;
